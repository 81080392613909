/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title:
    "Varendra Maurya: Tech Entrepreneur | IIT Dharwad Alumnus | Doorstep Dailies | Techwize360",
  description:
    "Explore the digital ventures of Varendra Maurya, an IIT Dharwad Computer Science Alumnus, pioneering transformative change with 'Doorstep Dailies' and 'Techwize360'. Discover innovative solutions at the nexus of business and technology, crafted by a visionary entrepreneur driving innovation and spicing up the tech journey. Connect for ingenious solutions blending tech and human touch! ",
  og: {
    title: "Varendra Maurya",
    type: "website",
    url: "https://www.varendra.co.in",
  },
};

//Home Page
const greeting = {
  title: "Varendra Maurya",
  logo_name: "VarendraMaurya",
  nickname:
    "Technical Entrepreneur: Crafting Solutions at the Nexus of Business and Technology",
  subTitle:
    "Welcome to the digital domain of Varendra Maurya, A Computer Science Alumnus from IIT Dharwad, with a knack for turning ideas into captivating digital experiences. I'm the force behind 'Doorstep Dailies,' a startup revolutionizing grocery delivery through smart tech.With a keen eye for innovation and a passion for entrepreneurship, I'm committed to driving transformative change in the digital landscape. I orchestrated marketing coups and led teams, spicing up my tech journey. If you're into ingenious solutions that blend tech and human touch, let's connect!",
  resumeLink:
    "https://www.google.com/search?q=varendra+maurya&oq=varendra+maurya",
  // portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/varendra007",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/varendra007",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/varendra-maurya/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Google",
    link: "https://www.google.com/search?q=varendra+maurya&oq=varendra+maurya",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:varendramaurya@gmail.com",
    fontAwesomeIcon: "fa fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/MauryaVarendra",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=100070153639569",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/varendra_maurya/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //   ],
    // },

    {
      title: "Backend Developer",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Master of backend wizardry, fluent in Python, Java (Spring Boot), Go, and Node.js.",
        "⚡ Craft artisanal RESTful APIs with elegance using Flask, FastAPI, Express.js, and Django.",
        "⚡ Architect of database realms, commanding MySQL, PostgreSQL, MongoDB, and Redis.",
        "⚡ Guardian of authentication realms, wielding OAuth, JWT, and session sorcery",
        "⚡ Proficient in using Docker for containerization and Kubernetes for orchestration to build scalable and resilient microservices architectures",
        "⚡ Experienced in deploying and managing applications on cloud platforms like AWS, Google Cloud Platform, or Microsoft Azure, using services like AWS Lambda, EC2, S3, or Google Cloud Functions",
        "⚡ Capable of optimizing backend performance by implementing caching strategies, query optimization techniques, and efficient resource management",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "FastAPI",
          fontAwesomeClassname: "simple-icons:fastapi",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "simple-icons:postman",
          style: {
            color: "#FF9900",
          },
        },
      ],
    },
    {
      title: "Database Engineer",
      fileName: "database_engineer",
      skills: [
        "⚡ Proficient in managing and optimizing various databases including MySQL, PostgreSQL, MongoDB, ClickHouse, and Redis.",
        "⚡ Skilled in writing complex SQL queries for relational databases like MySQL and PostgreSQL.",
        "⚡ Experienced in schema design, indexing, and performance tuning for efficient data retrieval and storage.",
        "⚡ Knowledgeable in NoSQL databases like MongoDB, adept at handling unstructured data and flexible schema requirements.",
        "⚡ Familiar with graph databases and technologies like GraphQL, enabling efficient management of interconnected data.",
        "⚡ Capable of implementing caching solutions using Redis for faster data access and improved performance",
        "⚡ Experienced in data modeling and query optimization techniques to ensure optimal database performance",
        "⚡ Able to design and implement scalable and fault-tolerant database architectures for high availability applications",
        "⚡ Proficient in data migration and synchronization between different database systems",
        "⚡ Passionate about staying updated with the latest database technologies and best practices to drive continuous improvement",
      ],
      softwareSkills: [
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#e10098",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Google Cloud",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#FFCA28",
          },
        },
      ],
    },
    {
      title: "Ethical Hacker",
      fileName: "ethical_hacker",
      skills: [
        "⚡ Skilled in identifying and assessing security vulnerabilities in computer systems, networks, and applications.",
        "⚡ Proficient in conducting penetration testing to evaluate the effectiveness of security measures and identify potential weaknesses.",
        "⚡ Experienced in ethical hacking techniques, including network scanning, exploitation, and social engineering.",
        "⚡ Knowledgeable in security assessment tools such as Metasploit, Nmap, Wireshark, and Burp Suite.",
        "⚡ Capable of analyzing and interpreting security assessment results to provide recommendations for improving security posture.",
        "⚡ Effective communicator, able to explain complex security concepts and findings to non-technical stakeholders.",
      ],
      softwareSkills: [
        {
          skillName: "Kali Linux",
          fontAwesomeClassname: "svg:kali-linux",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Front-End Developer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Proficient in HTML, CSS, and JavaScript, with a strong understanding of modern web development principles.",
        "⚡ Experienced in building responsive and mobile-first websites and web applications",
        "⚡ Skilled in using front-end frameworks such as React.js, Angular, or Vue.js to develop dynamic user interfaces.",
        "⚡ Experienced in implementing web accessibility standards to ensure inclusive user experiences for all users",
        "⚡  Capable of optimizing web performance by implementing best practices such as code splitting, lazy loading, and image optimization ",
        "⚡ Comfortable working with RESTful APIs to fetch and manipulate data, and experienced in integrating third-party APIs into web applications",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },

        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Mobile Application Developer",
      fileName: "mobile",
      skills: [
        "⚡ Skilled in creating mobile apps using React Native, a versatile framework.",
        "⚡ Develop apps that work smoothly on both iOS and Android platforms.",
        "⚡ Integrate app features with backend services and APIs seamlessly.",
        "⚡ Ensure apps run fast and smoothly, optimizing performance for a great user experience.",
        "⚡ Keen on exploring new technologies to keep apps up-to-date and innovative.",
      ],
      softwareSkills: [
        {
          skillName: "React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "React Native",
          fontAwesomeClassname: "simple-icons:redux",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Indian Institute  Technology Dharwad",
      subtitle: "B.Tech. in Computer Science and Engineering",
      logo_path: "iitdh_logo.png",
      alt_name: "IITDh",
      duration: "2020 - 2024",
      descriptions: [
        "⚡ Languages I speak: C/C++, Python, Java, JavaScript, TypeScript, GoLang, SQL",
      ],
      website_link: "https://iitdh.ac.in",
    },
  ],
};

const experienceCertifications = {
  certifications: [
    {
      title: "Event Manager",
      subtitle: "- PARSEC",
      logo_path: "parsec.png",
      certificate_link:
        "https://drive.google.com/file/d/1JQ0H-IdpdDiYELPLY8BmYd23ZqVdcsBM/view?usp=share_link",
      alt_name: "PARSEC",
      color_code: "#0f1c7c",
    },
    {
      title: "Google Summer of Code",
      subtitle: "- OWASP Foundation",
      logo_path: "google_summer_of_code.png",
      certificate_link:
        "https://drive.google.com/file/d/1DPmRS4U3w3P5rh4O7xDMj7hx0YadwFkR/view?usp=share_link",
      alt_name: "Google Summer of Code",
      color_code: "#ffff",
    },
    {
      title: "Google Cloud Facilitator",
      subtitle: "- Google Cloud ",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1ZNooqO6UwydoV-XsXn3muy2KQvWLL7ri/view?usp=share_link",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Google Cloud Facilitator Badge",
      subtitle: "- Google Cloud ",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1bTV_fM-11Ec4j1wMGQsht9B1rpXtAJQp/view?usp=share_link",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "Data Structures and Algorithms using C and C++",
    //   subtitle: "- Abdul Bari",
    //   logo_path: "logo-udemy.svg",
    //   certificate_link:
    //     "https://drive.google.com/file/d/19Q09CeW885Tw8EGdw7ES3H2WP72OSmjh/view?usp=share_link",
    //   alt_name: "Udemy",
    //   color_code: "#a435f09c",
    // },

    {
      title: "Data Structures and Algorithms using C and C++",
      subtitle: "- Abdul Bari",
      logo_path: "logo-udemy.svg",
      certificate_link:
        "https://drive.google.com/file/d/19Q09CeW885Tw8EGdw7ES3H2WP72OSmjh/view?usp=share_link",
      alt_name: "Udemy",
      color_code: "#a435f09c",
    },
    {
      title: "Ethical Hacking",
      subtitle: "- Zaid Sabih",
      logo_path: "logo-udemy.svg",
      certificate_link:
        "https://drive.google.com/file/d/1RH94L13zOzxNSnMy2TWlGhHbNwq6Rjqr/view?usp=share_link",
      alt_name: "Udemy",
      color_code: "#a435f09c",
    },
    {
      title: "Artificial Intelligence",
      subtitle: "- Ligency Team",
      logo_path: "logo-udemy.svg",
      certificate_link:
        "https://drive.google.com/file/d/1GamxbpbTyuCcUCfmXxiPRt60Rew-syzX/view?usp=share_link",
      alt_name: "Udemy",
      color_code: "#a435f09c",
    },
    {
      title: "Game Development with Unity",
      subtitle: "- Awesome Tuts",
      logo_path: "logo-udemy.svg",
      certificate_link:
        "https://drive.google.com/file/d/1RH94L13zOzxNSnMy2TWlGhHbNwq6Rjqr/view?usp=share_link",
      alt_name: "Udemy",
      color_code: "#a435f09c",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: `Delve into my professional timeline, a testament to my drive, dedication, and tech prowess. From being the force behind Doorstep Dailies' transformational journey in rural grocery delivery to my collaboration with the esteemed OWASP Foundation, each stint has been a learning curve, a challenge met with enthusiasm and expertise. Here, you'll find detailed insights into my roles, responsibilities, and the impact I've made in various capacities. Walk with me on this journey that marries my passion for tech with tangible real-world applications.`,
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "CEO and Founder",
          company: "Doorstep Dailies",
          company_url:
            "https://play.google.com/store/apps/details?id=com.doorstepdailies",
          logo_path: "doorstep_dailies.png",
          duration: "June 2023-Present",
          location: "India",
          description: [
            "Founded and developed a user-centric mobile platform to transform grocery delivery in rural areas.",
            "Solely architected and launched the platform, leveraging React, React Native, MongoDB, and Django to optimize order and delivery experiences.",
            "Led multifunctional teams in groundbreaking marketing campaigns, strengthening brand visibility and driving robust customer engagement.",
          ],
          color: "#6c7fdb",
        },
        {
          title: "Software Developer",
          company: "Needl.AI",
          company_url: "https://www.needl.ai/",
          logo_path: "needl.png",
          duration: "Sep 2023-Dec 2023",
          location: "India",
          description: [
            "Developed a unified notification service to seamlessly manage the notifications efficiently.",
            "Collaborated with the data pipeline team to enable user access to feeds from the Indian BSE exchange sources.",
            "Introduced and established automation testing with Playwright in their product.",
          ],
          color: "#6c7fdb",
        },
        {
          title: "Google Summer of Code",
          company: "OWASP Foundation",
          company_url: "https://owasp.org/",
          logo_path: "google_summer_of_code.png",
          duration: "June 2022-Sep 2022",
          location: "USA",
          description: [
            "Collaborated with the esteemed OWASP Foundation, taking the lead in the inception and development of an innovative automated video editor application.",
            "Utilised React for user interface design and Django for backend integration.",
          ],
          color: "#6c7fdb",
        },
        {
          title: "Event Manager",
          company: "PARSEC, IIT Dharwad",
          company_url: "https://parsec.iitdh.ac.in/",
          logo_path: "favicon.jpg",
          duration: "Nov 2021-Apr 2022",
          location: "Dharwad, Karnataka",
          description: [
            "Demonstrated effective leadership by skillfully coordinating various elements of significant events, striving for both technical excellence and impeccable execution.",
            "Led the development team, innovating a React website for the annual tech-fest at IIT Dharwad.",
            `Discerned a substantial influx of around 100,000 visitors to the website during the event by employing Google
Analytics.`,
          ],
          color: "#0879bf",
        },
        {
          title: "Full Stack Developer | Product Developer Lead",
          company: "AffixLearn",
          company_url: "",
          logo_path: "affixlearn.png",
          duration: "Jan 2022-May 2022",
          location: "Dharwad, Karnataka",
          description: [
            "Designed a visually stunning website for the company’s MVP using React.",
            "Created a platform adaptable across diverse devices, meeting modern front-end standards.",
          ],
          color: "#6c7fdb",
        },
        {
          title: "Gooogle Cloud Facillator",
          company: "Google Cloud",
          company_url: "https://cloud.google.com/",
          logo_path: "gcp_logo.png",
          duration: "March 2022 - July 2022",
          location: "Remote",
          description: [
            "Mentored and empowered 250 students nationwide through the Google Cloud initiative",
            "Enhanced their proficiency in cutting-edge Google Cloud services, fostering academic and professional success.",
            "Played a pivotal role in elevating the skills and achievements of a diverse cohort of students.",
          ],
          color: "#181717",
        },
        {
          title: "Contingent Leader of IIT Dharwad ",
          company: "Inter IIT Tech Meet 10.0",
          company_url: "https://interiit-tech.org/",
          logo_path: "iitdh_logo.png",
          duration: "Jan 2022 - April 2022",
          location: "IIT Kharagpur",
          description: [
            "Led the official contingent of IIT Dharwad in the 10th Inter-IIT Tech meet.",
            "Orchestrated seamless coordination, clinching IIT Dharwad accolades at Inter-IIT and PAN IIT meetings.",
          ],
          color: "#181717",
        },
      ],
    },

    // {
    //   title: "Volunteerships",
    //   experiences: [],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description: `The projects showcased here are a testament to my dedication to tech innovation and user-centric design. From devising an optimal route allocation system enhancing delivery efficiency to constructing a vibrant social networking platform, my endeavors reflect my commitment to addressing real-world challenges. Each project, marked by distinctive technologies like React, React Native, FastAPI, Django, NodeJS, MongoDB & MYSQL, tells a story of challenges met, solutions crafted, and boundaries pushed. Navigate through to witness the harmony of code and creativity.`,
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};
const projects = {
  projects: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "NWKRTC Bus Tracking System",
      createdAt: "Dec 2022",
      url: "http://tracknwkrtc.in/",
      description:
        "Adopted by Karnataka Government for daily public transport operations.",
      isFork: false,
      languages: [
        { name: "React", iconifyClass: "logos-react" },
        { name: "Java", iconifyClass: "logos-java" },
        { name: "Flutter", iconifyClass: "logos-flutter" },
        { name: "MySQL", iconifyClass: "logos-mysql" },
      ],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkxODIxMjk3NTQ=",
      name: "Doorstep Dailies",
      createdAt: "Jun 2023",
      url: "https://play.google.com/store/apps/details?id=com.doorstepdailies",
      description: `Initiated and spearheaded the creation of a user-centric mobile platform aimed at revolutionizing grocery delivery services in rural regions. Taking on full responsibility, architected and successfully launched the platform, utilizing technologies such as React, React Native, MongoDB, and Django to enhance order and delivery processes.`,
      isFork: false,
      languages: [
        { name: "React native", iconifyClass: "logos-react" },
        { name: "Django", iconifyClass: "logos-django" },
        { name: "MongoDB", iconifyClass: "logos-mongodb" },
        { name: "React", iconifyClass: "logos-react" },
      ],
    },
    {
      id: "MDEwOlJlcG9dzaXRvcnkyNDU0NjcyNzQ=",
      name: "Intelligent Delivery System",
      createdAt: "Feb 2023",
      url: "https://github.com/orgs/Grow-Simple-2023/repositories",
      description:
        "Contributed to optimizing route allocation with a team using K-Means, FastAPI, and Bing Maps, improving delivery efficiency. Outperformed competing teams by implementing parallelism, achieving an 80% improvement in performance.",
      isFork: false,
      languages: [
        { name: "React", iconifyClass: "logos-react" },
        { name: "Python", iconifyClass: "logos-python" },
        { name: "MongoDB", iconifyClass: "logos-mongodb" },
      ],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNjkwNzUwMjM=",
      name: "TechSio",
      createdAt: "Oct 2021",
      url: "https://github.com/varendra007/TechSio",
      description:
        "A vibrant social networking platform tailored for memory sharing and interaction.",
      isFork: false,
      languages: [
        { name: "PHP", iconifyClass: "logos-php" },
        { name: "CSS", iconifyClass: "logos-css-3" },
        { name: "JavaScript", iconifyClass: "logos-javascript" },
        { name: "MySQL", iconifyClass: "logos-mysql" },
      ],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNzM1MTI1NTE=",
      name: "Shop App",
      createdAt: "June 2021",
      url: "https://github.com/varendra007/shop-app",
      description:
        "A mobile application build in react native for online shopping. Redux store is setup & irebase is used to manage back-end logic to drive the application.",
      isFork: false,
      languages: [
        { name: "React Native", iconifyClass: "logos-react" },
        { name: "Firebase", iconifyClass: "logos-firebase" },
        { name: "Redux", iconifyClass: "logos-redux" },
      ],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkxNTU5NTE3NTk=",
      name: "Internship Management System",
      createdAt: "Nov 2022",
      url: "https://github.com/varendra007/Internship-management",
      description:
        "A internship management platform build in React, FastAPI, and MySQL database to handle intern's for Big Companies.",
      isFork: false,
      languages: [
        { name: "React", iconifyClass: "logos-react" },
        { name: "Python", iconifyClass: "logos-python" },
        { name: "MySQL", iconifyClass: "logos-mysql" },
      ],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyMDIxNDc4ODA=",
      name: "Library Management System",
      createdAt: "May 2021",
      url: "https://github.com/varendra007/library-management-system",
      description:
        "A terminal based library management system to manage all the works of a librarian.",
      isFork: false,
      languages: [{ name: "C", iconifyClass: "logos-c" }],
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkxODIxMjk3NTQ=",
      name: "Recipe Shop",
      createdAt: "Aug 2021",
      url: "https://github.com/varendra007/recipie-shop",
      description: "A recipe shop in react native.",
      isFork: false,
      languages: [
        { name: "React native", iconifyClass: "logos-react" },
        { name: "Redux", iconifyClass: "logos-redux" },
      ],
    },
    {
      id: "MDEwOlJlcG9asdzaXRvcnkxODIxMjk3NTQ=",
      name: "Lumberjack Simulation",
      createdAt: "Oct 2021",
      url: "https://github.com/varendra007/lumberjack-simulation",
      description: `Developed an efficient algorithm to maximize the profit of a lumberjack in a simulated forest grid.`,
      isFork: false,
      languages: [{ name: "C++", iconifyClass: "logos-c" }],
    },
  ],
};
const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profil.png",
    description:
      "Got a project in mind or just want to say hello? I'm all ears! Your thoughts, feedback, or collaboration ideas are invaluable to me. Whether it's a potential opportunity or a simple query, don't hesitate to reach out. Connect with me via email at varendramaurya@gmail.com or through my social channels listed below. Let's forge connections and create digital magic together!",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://ashutoshhathidara.wordpress.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Jhunsi, Prayagraj, Uttar Pradesh, India, 211019",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/oALoQTqVkG88yx6eA",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 8924873973",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  projects,
  experienceCertifications,
};
