import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { experience, experienceCertifications } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./ExperienceImg";
import "../../containers/certifications/Certifications.css";
import CertificationCard from "../../components/certificationCard/CertificationCard";

class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main">
        <Header theme={theme} />
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-img-div">
                {/* <img
									src={require(`../../assests/images/${experience["header_image_path"]}`)}
									alt=""
								/> */}
                <ExperienceImg theme={theme} />
              </div>
              <div className="experience-heading-text-div">
                <h1
                  className="experience-heading-text"
                  style={{ color: theme.text }}
                >
                  {experience.title}
                </h1>
                <h3
                  className="experience-heading-sub-text"
                  style={{ color: theme.text }}
                >
                  {experience["subtitle"]}
                </h3>
                <p
                  className="experience-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  <ul>
                    {experience["description"]}
                    {/* {experience["description"].map((el, ind) => (
                      <li>{el}</li>
                    ))} */}
                  </ul>
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <ExperienceAccordion sections={experience["sections"]} theme={theme} />
        {experienceCertifications.certifications.length > 0 ? (
          <div className="basic-education" id="certs">
            <div className="certs-header-div">
              <Fade bottom duration={2000} distance="20px">
                <h1 className="certs-header" style={{ color: theme.text }}>
                  Certifications
                </h1>
              </Fade>
            </div>
            <div style={{ textAlign: "center" }}>
              {experienceCertifications.certifications.map((cert) => {
                return (
                  <div style={{ display: "inline", margin: "20px" }}>
                    <CertificationCard certificate={cert} theme={theme} />
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
