import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import DataScienceImg from "./DataScienceImg";
import FullStackImg from "./FullStackImg";
import CloudInfraImg from "./CloudInfraImg";
import DesignImg from "./DesignImg";

function GetSkillSvg(props) {
  if (props.fileName === "mobile")
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          width="500"
          height="596.3292"
          viewBox="0 0 717.67004 596.3292"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <rect
            x="204.85476"
            width="326"
            height="594"
            rx="58.72835"
            fill="#3f3d56"
          />
          <path
            d="M766.5202,210.56773v476.54a53.29169,53.29169,0,0,1-53.23,53.23h-208.54a53.29169,53.29169,0,0,1-53.23-53.23v-476.54a53.29173,53.29173,0,0,1,53.23-53.23h27.27v9.42a16.08092,16.08092,0,0,0,16.08,16.08h121.84a16.08092,16.08092,0,0,0,16.08-16.08v-9.42h27.27A53.29174,53.29174,0,0,1,766.5202,210.56773Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <path
            d="M444.51974,272.33541a4.0046,4.0046,0,0,1-4-4v-8a4,4,0,0,1,8,0v8A4.00458,4.00458,0,0,1,444.51974,272.33541Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#3f3d56"
          />
          <path
            d="M444.51974,313.35689a4.00458,4.00458,0,0,1-4-4V287.33541a4,4,0,0,1,8,0v22.02148A4.00458,4.00458,0,0,1,444.51974,313.35689Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#3f3d56"
          />
          <path
            d="M444.51974,346.33541a4.00458,4.00458,0,0,1-4-4V320.31392a4,4,0,0,1,8,0v22.02149A4.00459,4.00459,0,0,1,444.51974,346.33541Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#3f3d56"
          />
          <path
            d="M772.51974,328.3354a4.00458,4.00458,0,0,1-4-4V270.602a4,4,0,0,1,8,0v53.7334A4.00459,4.00459,0,0,1,772.51974,328.3354Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#3f3d56"
          />
          <path
            d="M617.61227,170.1812h-31a2.5,2.5,0,0,1,0-5h31a2.5,2.5,0,0,1,0,5Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <circle cx="392.44729" cy="15.8458" r="2" fill="#fff" />
          <path
            d="M873.917,720.85262a3.56386,3.56386,0,0,0,2.52393-6.20136c-.09328-.3563-.1602-.61213-.25341-.96849a9.57474,9.57474,0,0,1,17.74853-.33644c4.31772,9.84578,9.88142,20.028,7.26661,30.94266,17.594-38.22733,11.03667-85.50774-15.81028-117.80022-7.37364-3.97084-13.38731-11.09311-15.01445-19.41415,3.642,1.19546,8.14-1.80048,5.37412-5.59841-1.197-1.45023-2.40544-2.88879-3.60237-4.33908,13.65013-14.81753,30.30051,8.10754,15.31045,28.074a114.26474,114.26474,0,0,1,13.024,19.95056,48.04337,48.04337,0,0,1,2.65565-22.34156c2.68222-6.64885,7.77474-12.27553,12.25158-18.044,5.443-7.0286,16.81306-3.91814,17.6387,4.92756-5.06867,1.71964-5.65929,9.07055.16646,10.02035-1.36654,16.4835-11.98172,31.72083-26.73177,39.08466a114.70965,114.70965,0,0,1,6.72483,47.88518c2.43494-19.011,22.77131-23.8125,36.94436-31.49419a5.54912,5.54912,0,0,1,8.516,5.53977,32.05053,32.05053,0,0,0-5.58948,3.01718,4.84411,4.84411,0,0,0,2.33454,8.82109c-7.01111,17.83934-25.46154,29.94947-44.52854,30.06385a117.92916,117.92916,0,0,1-7.70134,23.201l-28.29843.26948c-.10449-.31345-.19885-.63723-.29316-.95078a32.15326,32.15326,0,0,0,7.82585-.5411c-7.00816-8.139-12.40748-12.27407-8.48327-23.76707Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#f2f2f2"
          />
          <path
            d="M326.16179,720.85262a3.56386,3.56386,0,0,1-2.52393-6.20136c.09328-.3563.1602-.61213.25341-.96849a9.57474,9.57474,0,0,0-17.74853-.33644c-4.31772,9.84578-9.88142,20.028-7.26661,30.94266-17.594-38.22733-11.03667-85.50774,15.81028-117.80022,7.37364-3.97084,13.38731-11.09311,15.01445-19.41415-3.642,1.19546-8.14-1.80048-5.37412-5.59841,1.19705-1.45023,2.40544-2.88879,3.60237-4.33908-13.65013-14.81753-30.30051,8.10754-15.31045,28.074a114.26474,114.26474,0,0,0-13.024,19.95056,48.04337,48.04337,0,0,0-2.65565-22.34156c-2.68222-6.64885-7.77474-12.27553-12.25158-18.044-5.443-7.0286-16.81306-3.91814-17.6387,4.92756,5.06867,1.71964,5.65929,9.07055-.16646,10.02035,1.36654,16.4835,11.98172,31.72083,26.73177,39.08466a114.70965,114.70965,0,0,0-6.72483,47.88518c-2.43494-19.011-22.77131-23.8125-36.94436-31.49419a5.54912,5.54912,0,0,0-8.516,5.53977,32.05053,32.05053,0,0,1,5.58948,3.01718,4.84411,4.84411,0,0,1-2.33454,8.82109c7.01111,17.83934,25.46154,29.94947,44.52854,30.06385a117.92916,117.92916,0,0,0,7.70134,23.201l28.29843.26948c.10449-.31345.19885-.63723.29316-.95078a32.15326,32.15326,0,0,1-7.82585-.5411c7.00816-8.139,12.40748-12.27407,8.48327-23.76707Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#f2f2f2"
          />
          <path
            d="M520.10563,180.90845h-27a5,5,0,0,1,0-10h27a5,5,0,0,1,0,10Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#e6e6e6"
          />
          <circle cx="455.94066" cy="24.07305" r="4.5" fill="#e6e6e6" />
          <circle cx="469.94066" cy="24.07305" r="4.5" fill="#e6e6e6" />
          <circle cx="483.94066" cy="24.07305" r="4.5" fill="#e6e6e6" />
          <path
            d="M693.95935,250.79253h-169.7a12.41709,12.41709,0,0,0-12.4,12.4v110.31a12.41711,12.41711,0,0,0,12.4,12.4h169.7a12.41069,12.41069,0,0,0,12.4-12.4v-110.31A12.41068,12.41068,0,0,0,693.95935,250.79253Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#f2f2f2"
          />
          <path
            d="M640.83209,297.8843a791.404,791.404,0,0,1-50.68162,78.41976H563.53819q26.1465-31.85316,52.33162-63.70633c5.008-6.11445,10.443-12.52,17.955-15.00452Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#3f3d56"
          />
          <path
            d="M605.46563,293.53629c-2.77585,2.8922-5.43512,5.90085-7.93907,9.02608-5.51273,6.63847-10.94773,13.33523-16.40217,20.01255-3.39689,4.15383-6.79379,8.30777-10.15182,12.46172a24.78863,24.78863,0,0,1-3.02808-2.29046A27.16074,27.16074,0,0,1,602.651,290.97405,25.03515,25.03515,0,0,1,605.46563,293.53629Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#6c63ff"
          />
          <path
            d="M706.35938,290.3825v4.03l-1.8-.66-25.33-9.26c-8.25-3.01-16.48-6.45-25-8.62a39.4016,39.4016,0,0,0-22.64.29,51.94969,51.94969,0,0,0-17.64,10.95c-2.25,2.08-4.43,4.21-6.54,6.42-.69.73-1.38,1.46-2.06,2.19-2.04,2.22-4.01,4.49-5.88,6.84-5.51,6.64-10.95,13.34-16.4,20.01-3.4,4.16-6.8,8.31-10.16,12.46q-16.83,20.595-33.46,41.27a19.53674,19.53674,0,0,1-4.43-.56q16.965-21.075,34.09-42.04.39-.48.78-.96c1.34-1.67,2.71-3.35,4.07-5.02q8.535-10.455,17.08-20.89c4.14-5.06,8.25-10.14,12.71-14.94.27-.31.55-.61.84-.92.43-.46.86-.91,1.3-1.38,9.69-10.01,21.78-18.86,36.26-19.1,8.52-.13,16.75,2.57,24.67,5.42005,8.53,3.09,17.03,6.23,25.55,9.35Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#3f3d56"
          />
          <path
            d="M710.3495,629.45363H507.6909a12.4134,12.4134,0,0,1-12.39918-12.39918V589.7417a12.4134,12.4134,0,0,1,12.39918-12.39918H710.3495a12.4134,12.4134,0,0,1,12.39918,12.39918v27.31275A12.4134,12.4134,0,0,1,710.3495,629.45363Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#f2f2f2"
          />
          <path
            d="M710.3495,489.45363H507.6909a12.4134,12.4134,0,0,1-12.39918-12.39918V449.7417a12.4134,12.4134,0,0,1,12.39918-12.39918H710.3495a12.4134,12.4134,0,0,1,12.39918,12.39918v27.31275A12.4134,12.4134,0,0,1,710.3495,489.45363Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#f2f2f2"
          />
          <path
            d="M710.3495,559.45363H507.6909a12.4134,12.4134,0,0,1-12.39918-12.39918V519.7417a12.4134,12.4134,0,0,1,12.39918-12.39918H710.3495a12.4134,12.4134,0,0,1,12.39918,12.39918v27.31275A12.4134,12.4134,0,0,1,710.3495,559.45363Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#f2f2f2"
          />
          <path
            d="M633.6415,598.72353H573.92982a2.67117,2.67117,0,0,1,0-5.34234H633.6415a2.67117,2.67117,0,0,1,0,5.34234Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <path
            d="M691.07162,613.415H573.262a2.67117,2.67117,0,0,1,0-5.34234h117.8096a2.67117,2.67117,0,0,1,0,5.34234Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <circle cx="301.04208" cy="451.56268" r="17.90945" fill="#fff" />
          <path
            d="M538.21638,627.58947a7.74428,7.74428,0,0,0-10.20256,3.54711,7.43134,7.43134,0,0,0-.43951,1.14687l-26.96921,1.61166-3.997,13.97247,36.7058-6.20206a7.7228,7.7228,0,0,0,8.48718-4.10633A7.39069,7.39069,0,0,0,538.4,627.676Q538.30879,627.63146,538.21638,627.58947Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#ffb6b6"
          />
          <path
            d="M478.68735,558.66975l0,0a13.37259,13.37259,0,0,0-18.04487,9.16224l-7.41461,29.9431,1.39472,52.38373s38.702,7.42638,45.19914,2.65273,5.88706-8.32722,6.4971-4.77365,3.39869,5.0447,3.43741,3.631.35417-1.17209,1.97323-1.59791,8.76491-3.05958,8.76491-3.05958l.32-14.93516s-11.81354-4.47869-14.67963-3.14244.61,3.55357-2.86608,1.33624-3.856-9.21572-4.45033-2.90852-2.3343,7.48153-4.94046,4.677-14.68055-1.01308-14.68055-1.01308l7.68342-58.22987A13.37258,13.37258,0,0,0,478.68735,558.66975Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#6c63ff"
          />
          <polygon
            points="498.157 562.178 498.564 551.148 456.212 544.329 455.612 560.608 498.157 562.178"
            fill="#ffb6b6"
          />
          <path
            d="M731.63407,717.33423l-.40158-17.66077,7.55.27858,12.18888-16.32676a4.71924,4.71924,0,0,1,8.498,2.997l-.7741,20.97961-7.60155,3.34059,7.416,1.68749-.29187,7.91015Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <path
            d="M500.9626,739.30782,635.10573,723.7091s57.92747,3.34588,71.51863-5.49564l14.33237-1.80941.7768-21.05266s-8.76425.06463-13.80315-2.28315-1.5369-5.2206-6.34117-2.39583-4.29677,3.16449-8.01127,1.86626-174.05-8.26107-174.05-8.26107Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <polygon
            points="436.563 578.337 444.363 570.527 417.953 536.724 406.441 548.25 436.563 578.337"
            fill="#ffb6b6"
          />
          <path
            d="M669.83324,727.37831l11.71713-13.22018,5.34542,5.33917,20.03985-3.67914a4.71923,4.71923,0,0,1,4.19152,7.97679l-14.83624,14.8536-7.84469-2.721,4.289,6.28091-5.59385,5.6004Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <path
            d="M448.03937,665.84252l1.33359,30.99956c1.56994,36.49347,41.75932,58.58065,72.92311,39.52662q.86156-.52677,1.72887-1.09132s11.81428-3.17271,11.45565-8.19723,2.52757-7.4699,5.08175-6.09972S576.55846,674.89,576.55846,674.89s69.48152,34.65878,84.80968,42.8177l16.17348-4.40663s6.08708,2.87491,3.89443-1.283-2.58585-2.16655-3.25951-6.69673-2.39688-10.14673-3.44173-9.91051-85.957-59.1949-85.957-59.1949-13.83321-14-30.9928-4.75634c-.3574,1.35782-.05185,1.40513-.05185,1.40513l-56.68436,46.18863-8.00942-15.21084Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <path
            d="M486.13806,551.93967l-24.30372-11.39046-7.2761,12.6606s-19.54183,27.88665-19.8253,48.00476,3.18465,26.10071,3.18465,26.10071-8.42568,6.29921-4.21284,10.14961,13.53954,5.51181,6.37619,7.1811-5.348,18.56858,2.652,21.56858,50.96418,2.97375,50.96418,2.97375a7.65139,7.65139,0,0,1,1.88976-7.53446c3.33071-3.33858,6.33071-.9429,3.33071-6.14074s-8-2.19784-4-9.19784,3.99854-9.09184,3.99854-9.09184,8.15437-15.51893-4.12807-32.35468c-.74869-1.02624-.11917-1.06581-.11917-1.06581l-9.918-33.2357Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#6c63ff"
          />
          <path
            d="M446.97141,504.04638c.20559,5.13373-3.02967,12.407-4.6229,16.79273-1.70331,4.6889,5.67924,10.91292,10.40462,12.27588a6.98917,6.98917,0,0,1,2.28864-4.19665,11.96706,11.96706,0,0,0-.08724,4.70808l39.94785,9.09437c7.18664-8.32238,14.41277-20.28686,12.83655-34.71671a14.03973,14.03973,0,0,0-6.761-14.00008l-20.1955-11.93054c-8.59021-1.95561-19.408-3.13636-25.61265,2.17895A24.57545,24.57545,0,0,0,446.97141,504.04638Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <circle cx="241.51391" cy="363.48857" r="23" fill="#ffb6b6" />
          <path
            d="M455.73424,503.03633l-1.10988,4.87526,19.40355,4.41734a7.15592,7.15592,0,0,1,2.38608-5.21032,12.19175,12.19175,0,0,0,.13928,5.78523c10.46445,5.872,19.89624,7.95233,28.35369,6.4549a4.43086,4.43086,0,0,0,5.30385-3.33675v0c3.40288-14.94742-5.51208-30.22473-20.38619-33.93493a27.99693,27.99693,0,0,0-34.09038,20.94929Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <path
            d="M467.07875,470.87084c2.55851-.55537,5.26612-.42114,7.57949-2.15411l-1.51712,3.48248a17.626,17.626,0,0,1,12.58531,1.257,6.7302,6.7302,0,0,1,2.8492,2.41205,4.95656,4.95656,0,0,1,.37479,4.26923,4.64131,4.64131,0,0,1-2.71808,2.58135,8.96267,8.96267,0,0,1-3.32585.34819q-7.08317-.165-14.16625-.33012a10.59127,10.59127,0,0,1-5.22462-1.01528,5.21975,5.21975,0,0,1-2.42395-5.18324C461.54208,473.37264,464.52026,471.42614,467.07875,470.87084Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#2f2e41"
          />
          <path
            d="M591.84361,651.13108a.98062.98062,0,0,0-.18816-.17287,2.01234,2.01234,0,0,0-.61614-.48219l-1.6832-.90095-11.17325-12.4049a2.80646,2.80646,0,0,0-2.09816-.2007l-.02874.0087-4.78642,1.48028-4.11644,1.27747-5.75023,1.78243L522.23673,653.646l-25.7448,7.97084a2.785,2.785,0,0,0-1.8295,3.448,2.89846,2.89846,0,0,0,.58784,1.04447l.81928.91173,1.71831,1.91427,3.98114,8.25041,1.10458,1.23285a2.775,2.775,0,0,0,2.86591.80414L590.5541,655.638a2.82416,2.82416,0,0,0,1.28951-4.50691Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#ccc"
          />
          <path
            d="M586.80812,528.4257l-1.64243.2662a1.72823,1.72823,0,0,0-1.11942.69471l-.72375,1.01191-6.5272,16.32176a1.72814,1.72814,0,0,0-.29667,1.28843l.7647,4.65037-2.89028,83.00559a1.74786,1.74786,0,0,0,.64654,1.08405l12.67291,16.95222.29589.23506.63823.49974a1.75568,1.75568,0,0,0,1.14665.36459l1.077-.05133a1.73016,1.73016,0,0,0,1.26549-.64526,1.70389,1.70389,0,0,0,.35943-1.36986L588.7987,529.8458a1.72477,1.72477,0,0,0-1.98213-1.42151Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#ccc"
          />
          <polygon
            points="342.893 383.04 337.111 400.653 334.874 471.007 343.874 479.007 342.893 383.04"
            fill="#6c63ff"
          />
          <path
            d="M539.34471,642.192a7.74428,7.74428,0,0,0-10.20256,3.54712,7.43055,7.43055,0,0,0-.43951,1.14687l-26.96922,1.61166-3.997,13.97246,36.70579-6.20206a7.7228,7.7228,0,0,0,8.48719-4.10633,7.3907,7.3907,0,0,0-3.401-9.88321Q539.43712,642.234,539.34471,642.192Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#ffb6b6"
          />
          <path
            d="M479.81568,573.27228l0,0a13.37258,13.37258,0,0,0-18.04487,9.16225l-7.41461,29.94309,1.39471,52.38373s38.70206,7.42639,45.19915,2.65274,5.88706-8.32722,6.4971-4.77365,3.39869,5.04469,3.43741,3.631.35416-1.1721,1.97323-1.59792,8.76491-3.05957,8.76491-3.05957l.32-14.93517s-11.81354-4.47868-14.67963-3.14244.61,3.55357-2.86608,1.33624-3.856-9.21572-4.45033-2.90851-2.3343,7.48152-4.94046,4.677-14.68055-1.01309-14.68055-1.01309l7.68342-58.22986A13.3726,13.3726,0,0,0,479.81568,573.27228Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#6c63ff"
          />
          <path
            d="M241.165,746.9746a1.18647,1.18647,0,0,0,1.19006,1.19h715.29a1.19,1.19,0,0,0,0-2.38H242.355A1.18651,1.18651,0,0,0,241.165,746.9746Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#ccc"
          />
          <path
            d="M872.3495,489.45363H669.6909a12.4134,12.4134,0,0,1-12.39918-12.39918V449.7417a12.4134,12.4134,0,0,1,12.39918-12.39918H872.3495a12.4134,12.4134,0,0,1,12.39918,12.39918v27.31275A12.4134,12.4134,0,0,1,872.3495,489.45363Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#e6e6e6"
          />
          <path
            d="M795.6415,458.72353H735.92982a2.67117,2.67117,0,1,1,0-5.34234H795.6415a2.67117,2.67117,0,1,1,0,5.34234Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <path
            d="M853.07162,473.415H735.262a2.67117,2.67117,0,1,1,0-5.34234h117.8096a2.67117,2.67117,0,0,1,0,5.34234Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <circle cx="463.04208" cy="311.56268" r="17.90945" fill="#fff" />
          <path
            d="M807.3495,559.45363H604.6909a12.4134,12.4134,0,0,1-12.39918-12.39918V519.7417a12.4134,12.4134,0,0,1,12.39918-12.39918H807.3495a12.4134,12.4134,0,0,1,12.39918,12.39918v27.31275A12.4134,12.4134,0,0,1,807.3495,559.45363Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#e6e6e6"
          />
          <path
            d="M730.6415,528.72353H670.92982a2.67117,2.67117,0,1,1,0-5.34234H730.6415a2.67117,2.67117,0,0,1,0,5.34234Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <path
            d="M788.07162,543.415H670.262a2.67117,2.67117,0,0,1,0-5.34234h117.8096a2.67117,2.67117,0,0,1,0,5.34234Z"
            transform="translate(-241.16498 -151.8354)"
            fill="#fff"
          />
          <circle cx="398.04208" cy="381.56268" r="17.90945" fill="#fff" />
        </svg>
        {/* <DataScienceImg theme={props.theme} /> */}
      </>
    );
  else if (props.fileName === "FullStackImg")
    return <FullStackImg theme={props.theme} />;
  else if (props.fileName === "CloudInfraImg")
    return <CloudInfraImg theme={props.theme} />;
  else if (props.fileName === "database_engineer")
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          width="841.59024"
          height="589"
          viewBox="0 0 841.59024 589"
        >
          <polygon
            points="743.208 574.193 729.128 574.192 722.43 519.885 743.21 519.886 743.208 574.193"
            fill="#ffb8b8"
          />
          <path
            d="M926.00334,743.34094l-45.39888-.00169V742.765A17.67147,17.67147,0,0,1,898.275,725.0948h.00112l27.72809.00112Z"
            transform="translate(-179.20488 -155.5)"
            fill="#2f2e41"
          />
          <polygon
            points="805.349 539.578 796.21 550.289 750.55 520.133 764.039 504.326 805.349 539.578"
            fill="#ffb8b8"
          />
          <path
            d="M997.26727,701.20576l-29.46919,34.5344-.43682-.37273a17.67143,17.67143,0,0,1-1.97223-24.91172l.00073-.00086,17.99885-21.09237Z"
            transform="translate(-179.20488 -155.5)"
            fill="#2f2e41"
          />
          <polygon
            points="643.236 307.117 638.853 347.537 816.024 345.01 738.759 219.114 675.673 216.848 643.236 307.117"
            fill="#2f2e41"
          />
          <polygon
            points="643.236 307.117 638.853 347.537 816.024 345.01 738.759 219.114 675.673 216.848 643.236 307.117"
            opacity="0.28"
          />
          <path
            d="M857.27966,469.55618l-6.25477,36.98405v33.05813L901.455,556.05955s-5.90374,128.40409,1.96683,136.58258l-.19337,19.74247,22,1,.17941-13.89387L934.735,595.635l5.49349-76.25041-8.90664-57.8109Z"
            transform="translate(-179.20488 -155.5)"
            fill="#2f2e41"
          />
          <path
            d="M896.35029,501.39223l-45.3254,38.20613s-2.6527,68.93184,4.08383,77.01567c5.786,6.94318,81.03774,66.83187,81.03774,66.83187s13.37442,12.26792,20.86947,12.46353,16.21255-15.52483,16.21255-15.52483l-17.35829-13.18827S910.901,625.22041,897.54884,608.5302c-5.38922-6.73653-5.38922-7.41018-5.38922-7.41018l1.84061-28.01427,18.52508-51.99127Z"
            transform="translate(-179.20488 -155.5)"
            fill="#2f2e41"
          />
          <polygon
            points="669.024 162.885 649.482 161.079 631.756 164.033 594.222 164.045 591.024 191.249 625.277 198.914 633.624 194.823 668.419 208.08 669.024 162.885"
            fill="#2f2e41"
          />
          <path
            d="M871.56928,318.29985s41.47357-3.88605,50.90471,3.52413,29.64072,154.26646,29.64072,154.26646l-80.54543,4.54583-14.4396-9.93505,4.0988-73.31662Z"
            transform="translate(-179.20488 -155.5)"
            fill="#ccc"
          />
          <polygon
            points="705.024 161.885 659.275 162.889 616.622 355.62 650.305 363.031 705.024 161.885"
            fill="#2f2e41"
          />
          <polygon
            points="737.546 160.256 773.583 171.535 771.536 250.722 816.024 345.396 763.725 350.905 722.386 248.675 737.546 160.256"
            fill="#2f2e41"
          />
          <path
            d="M976.17566,510.51266a11.51329,11.51329,0,0,0-.43624-17.64888l7.24981-25.2913-14.29491-8.15427-9.73651,35.86a11.5757,11.5757,0,0,0,17.21785,15.23444Z"
            transform="translate(-179.20488 -155.5)"
            fill="#ffb8b8"
          />
          <path
            d="M931.90513,329.23416l21.32335-1.84956s19.1431,20.02168,23.4982,35.52394,9.07066,64.00526,9.07066,64.00526l-1.96706,70.95509L958.90513,480.9427l-1.67172-61.03065Z"
            transform="translate(-179.20488 -155.5)"
            fill="#2f2e41"
          />
          <circle
            cx="900.23883"
            cy="279.0718"
            r="30.2266"
            transform="translate(44.35273 779.63092) rotate(-61.33681)"
            fill="#ffb8b8"
          />
          <path
            d="M896.86876,266.45286c-3.96584-.91637-8.08269.09909-12.09946.75737s-8.44336.87528-11.84109-1.36586c-2.50928-1.65512-4.052-4.39327-5.5007-7.02712a8.08807,8.08807,0,0,1-1.18324-3.10061c-.17057-1.94791,1.06856-3.72514,2.3844-5.17153,6.112-6.71846,15.04376-10.39563,24.051-11.56369a41.55433,41.55433,0,0,1,21.96117,2.68176c6.83748,2.99893,12.71514,8.49079,15.40335,15.45628a43.8945,43.8945,0,0,1,2.30051,11.03757,32.97038,32.97038,0,0,1,.15507,8.35909,35.83247,35.83247,0,0,1-1.61995,6.056l-4.453,13.446a8.85863,8.85863,0,0,1-1.751,3.466,3.02927,3.02927,0,0,1-3.54735.77718,11.03957,11.03957,0,0,0,.15962-4.46052,3.76376,3.76376,0,0,0-2.96974-2.99493c-1.64132-.17418-3.04344,1.09814-4.60042,1.64611a5.65951,5.65951,0,0,1-7.03462-3.81764q-.06393-.21546-.11053-.43552c-.24579-1.53722.1669-3.18288-.41576-4.62647-.68269-1.69135-2.493-2.57757-3.853-3.79294a8.86863,8.86863,0,0,1-2.4334-9.21678c.36409-1.119,1.39236-1.62773,1.18665-2.91325C900.78734,267.96287,898.30087,266.78376,896.86876,266.45286Z"
            transform="translate(-179.20488 -155.5)"
            fill="#2f2e41"
          />
          <path
            d="M560.81664,394.22516H179.20488V155.5H560.81664Z"
            transform="translate(-179.20488 -155.5)"
            fill="#fff"
          />
          <path
            d="M560.81664,394.22516H179.20488V155.5H560.81664ZM180.9474,392.48264H559.07412V157.24252H180.9474Z"
            transform="translate(-179.20488 -155.5)"
            fill="#3f3d56"
          />
          <path
            d="M514.20424,724.86865H296.38931V486.14349H514.20424Z"
            transform="translate(-179.20488 -155.5)"
            fill="#fff"
          />
          <path
            d="M514.20424,724.86865H296.38931V486.14349H514.20424Zm-216.07241-1.74252H512.46172V487.886H298.13183Z"
            transform="translate(-179.20488 -155.5)"
            fill="#3f3d56"
          />
          <rect
            x="260.07105"
            y="546.82166"
            width="45.30551"
            height="1.8367"
            fill="#3f3d56"
          />
          <rect
            x="260.07105"
            y="552.33175"
            width="45.30551"
            height="1.8367"
            fill="#3f3d56"
          />
          <rect
            x="260.07105"
            y="557.84189"
            width="45.30551"
            height="1.8367"
            fill="#3f3d56"
          />
          <path
            d="M405.2968,528.835v19.16772a57.50315,57.50315,0,0,1,40.66088,98.164l13.55361,13.55361A76.67086,76.67086,0,0,0,405.2968,528.835Z"
            transform="translate(-179.20488 -155.5)"
            fill="#e6e6e6"
          />
          <path
            d="M459.51129,659.72034l-13.55361-13.55361a57.48875,57.48875,0,0,1-75.4511,5.11572l-11.60149,15.26513A76.66891,76.66891,0,0,0,459.51129,659.72034Z"
            transform="translate(-179.20488 -155.5)"
            fill="#575a89"
          />
          <path
            d="M347.79366,605.50585a57.50312,57.50312,0,0,1,57.50314-57.50314V528.835a76.6701,76.6701,0,0,0-46.39171,137.71259l11.60152-15.26513A57.403,57.403,0,0,1,347.79366,605.50585Z"
            transform="translate(-179.20488 -155.5)"
            fill="#6c63ff"
          />
          <path
            d="M836.57034,576.7545H618.75541V338.02891H836.57034Z"
            transform="translate(-179.20488 -155.5)"
            fill="#fff"
          />
          <path
            d="M836.57034,576.7545H618.75541V338.02891H836.57034ZM620.49793,575.012H834.82782V339.77143H620.49793Z"
            transform="translate(-179.20488 -155.5)"
            fill="#3f3d56"
          />
          <rect
            x="470.9159"
            y="328.46512"
            width="39.20669"
            height="62.7307"
            fill="#575a89"
          />
          <rect
            x="527.54778"
            y="285.77339"
            width="39.20669"
            height="105.42243"
            fill="#e6e6e6"
          />
          <rect
            x="584.17967"
            y="236.11159"
            width="39.20669"
            height="155.08423"
            fill="#6c63ff"
          />
          <polygon
            points="636.455 392.939 460.461 392.939 460.461 210.845 462.203 210.845 462.203 391.196 636.455 391.196 636.455 392.939"
            fill="#3f3d56"
          />
          <circle cx="594.0236" cy="176.8846" r="12" fill="#ffb8b8" />
          <path
            d="M1019.79512,744.5h-187a1,1,0,0,1,0-2h187a1,1,0,0,1,0,2Z"
            transform="translate(-179.20488 -155.5)"
            fill="#ccc"
          />
          <path
            d="M507.81987,344.2986H232.20165a.86259.86259,0,0,1-.86256-.86255V211.33223a.86256.86256,0,0,1,1.72512,0V342.57349H507.81987a.86256.86256,0,1,1,0,1.72511Z"
            transform="translate(-179.20488 -155.5)"
            fill="#3f3d56"
          />
          <path
            d="M291.08926,334.81045H266.02071a2.56337,2.56337,0,0,1-2.56052-2.5603V297.693a2.56337,2.56337,0,0,1,2.56052-2.5603h25.06855a2.56337,2.56337,0,0,1,2.56051,2.5603v34.55712A2.56337,2.56337,0,0,1,291.08926,334.81045Z"
            transform="translate(-179.20488 -155.5)"
            fill="#6c63ff"
          />
          <path
            d="M336.8049,334.81045H311.73635a2.56336,2.56336,0,0,1-2.56051-2.5603V264.91577a2.56337,2.56337,0,0,1,2.56051-2.5603H336.8049a2.56337,2.56337,0,0,1,2.56051,2.5603v67.33438A2.56336,2.56336,0,0,1,336.8049,334.81045Z"
            transform="translate(-179.20488 -155.5)"
            fill="#6c63ff"
          />
          <path
            d="M382.52054,334.81045H357.452a2.56337,2.56337,0,0,1-2.56051-2.5603V297.693a2.56337,2.56337,0,0,1,2.56051-2.5603h25.06855a2.56337,2.56337,0,0,1,2.56052,2.5603v34.55712A2.56337,2.56337,0,0,1,382.52054,334.81045Z"
            transform="translate(-179.20488 -155.5)"
            fill="#6c63ff"
          />
          <path
            d="M428.23619,334.81045H403.16763a2.50734,2.50734,0,0,1-2.56051-2.44431V251.8614a2.50734,2.50734,0,0,1,2.56051-2.44432h25.06856a2.50734,2.50734,0,0,1,2.56051,2.44432v80.50474A2.50734,2.50734,0,0,1,428.23619,334.81045Z"
            transform="translate(-179.20488 -155.5)"
            fill="#6c63ff"
          />
          <path
            d="M473.95183,334.81045H448.88328a2.56336,2.56336,0,0,1-2.56051-2.5603V228.68828a2.56337,2.56337,0,0,1,2.56051-2.5603h25.06855a2.56337,2.56337,0,0,1,2.56051,2.5603V332.25015A2.56337,2.56337,0,0,1,473.95183,334.81045Z"
            transform="translate(-179.20488 -155.5)"
            fill="#6c63ff"
          />
          <circle cx="99.3501" cy="124.10666" r="5.17536" fill="#3f3d56" />
          <circle cx="145.06574" cy="90.46685" r="5.17536" fill="#3f3d56" />
          <circle cx="190.78139" cy="124.10666" r="5.17536" fill="#3f3d56" />
          <circle cx="236.49703" cy="74.07822" r="5.17536" fill="#3f3d56" />
          <circle cx="282.21267" cy="55.10191" r="5.17536" fill="#3f3d56" />
          <polygon
            points="190.89 125.266 145.066 90.943 99.867 124.797 98.833 123.416 145.066 88.787 190.672 122.947 235.993 73.352 236.175 73.278 281.89 54.858 282.535 56.459 237.001 74.805 190.89 125.266"
            fill="#3f3d56"
          />
        </svg>
      </>
    );
  else if (props.fileName === "ethical_hacker")
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          width="1042.03903"
          height="709.08851"
          viewBox="0 0 1042.03903 709.08851"
        >
          <title>hacker_mind</title>
          <rect width="875" height="470.67901" fill="#3f3d56" />
          <rect
            x="158.17901"
            y="66.35802"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="100.30864"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="213.73457"
            y="81.79012"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="213.73457"
            y="101.85185"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="140.4321"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="213.73457"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="236.11111"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="158.17901"
            y="86.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="158.17901"
            y="279.32099"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="158.17901"
            y="319.44444"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="158.17901"
            y="339.50617"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="155.09259"
            y="108.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="196.75926"
            y="126.54321"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="155.09259"
            y="206.79012"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="155.09259"
            y="223.76543"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="196.75926"
            y="322.53086"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 121.914 158.179 125 161.265 125 161.265 131.173 158.179 131.173 158.179 134.259 165.895 134.259 165.895 121.914 158.179 121.914"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 100.309 184.414 103.395 187.5 103.395 187.5 109.568 184.414 109.568 184.414 112.654 192.13 112.654 192.13 100.309 184.414 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="213.735 120.37 213.735 123.457 216.821 123.457 216.821 129.63 213.735 129.63 213.735 132.716 221.451 132.716 221.451 120.37 213.735 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="213.735 180.556 213.735 183.642 216.821 183.642 216.821 189.815 213.735 189.815 213.735 192.901 221.451 192.901 221.451 180.556 213.735 180.556"
            fill="#6c63ff"
          />
          <polygon
            points="213.735 200.617 213.735 203.704 216.821 203.704 216.821 209.877 213.735 209.877 213.735 212.963 221.451 212.963 221.451 200.617 213.735 200.617"
            fill="#6c63ff"
          />
          <polygon
            points="213.735 138.889 213.735 141.975 216.821 141.975 216.821 148.148 213.735 148.148 213.735 151.235 221.451 151.235 221.451 138.889 213.735 138.889"
            fill="#6c63ff"
          />
          <polygon
            points="198.302 81.79 198.302 84.877 201.389 84.877 201.389 91.049 198.302 91.049 198.302 94.136 206.019 94.136 206.019 81.79 198.302 81.79"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="180.55556"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="217.59259"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="198.302 199.074 198.302 202.16 201.389 202.16 201.389 208.333 198.302 208.333 198.302 211.42 206.019 211.42 206.019 199.074 198.302 199.074"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 120.37 184.414 123.457 187.5 123.457 187.5 129.63 184.414 129.63 184.414 132.716 192.13 132.716 192.13 120.37 184.414 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 140.432 184.414 143.519 187.5 143.519 187.5 149.691 184.414 149.691 184.414 152.778 192.13 152.778 192.13 140.432 184.414 140.432"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 180.556 184.414 183.642 187.5 183.642 187.5 189.815 184.414 189.815 184.414 192.901 192.13 192.901 192.13 180.556 184.414 180.556"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 220.679 184.414 223.765 187.5 223.765 187.5 229.938 184.414 229.938 184.414 233.025 192.13 233.025 192.13 220.679 184.414 220.679"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="277.77778"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 260.802 184.414 263.889 187.5 263.889 187.5 270.062 184.414 270.062 184.414 273.148 192.13 273.148 192.13 260.802 184.414 260.802"
            fill="#6c63ff"
          />
          <polygon
            points="213.735 260.802 213.735 263.889 216.821 263.889 216.821 270.062 213.735 270.062 213.735 273.148 221.451 273.148 221.451 260.802 213.735 260.802"
            fill="#6c63ff"
          />
          <rect
            x="292.43827"
            y="81.79012"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="292.43827"
            y="101.85185"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="292.43827"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="292.438 120.37 292.438 123.457 295.525 123.457 295.525 129.63 292.438 129.63 292.438 132.716 300.154 132.716 300.154 120.37 292.438 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="292.438 180.556 292.438 183.642 295.525 183.642 295.525 189.815 292.438 189.815 292.438 192.901 300.154 192.901 300.154 180.556 292.438 180.556"
            fill="#6c63ff"
          />
          <polygon
            points="292.438 200.617 292.438 203.704 295.525 203.704 295.525 209.877 292.438 209.877 292.438 212.963 300.154 212.963 300.154 200.617 292.438 200.617"
            fill="#6c63ff"
          />
          <polygon
            points="292.438 138.889 292.438 141.975 295.525 141.975 295.525 148.148 292.438 148.148 292.438 151.235 300.154 151.235 300.154 138.889 292.438 138.889"
            fill="#6c63ff"
          />
          <polygon
            points="292.438 260.802 292.438 263.889 295.525 263.889 295.525 270.062 292.438 270.062 292.438 273.148 300.154 273.148 300.154 260.802 292.438 260.802"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="297.83951"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="184.41358"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="198.30247"
            y="336.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 300.926 184.414 304.012 187.5 304.012 187.5 310.185 184.414 310.185 184.414 313.272 192.13 313.272 192.13 300.926 184.414 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="184.414 395.062 184.414 398.148 187.5 398.148 187.5 404.321 184.414 404.321 184.414 407.407 192.13 407.407 192.13 395.062 184.414 395.062"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 100.309 269.29 103.395 272.377 103.395 272.377 109.568 269.29 109.568 269.29 112.654 277.006 112.654 277.006 100.309 269.29 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 120.37 269.29 123.457 272.377 123.457 272.377 129.63 269.29 129.63 269.29 132.716 277.006 132.716 277.006 120.37 269.29 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 140.432 269.29 143.519 272.377 143.519 272.377 149.691 269.29 149.691 269.29 152.778 277.006 152.778 277.006 140.432 269.29 140.432"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 180.556 269.29 183.642 272.377 183.642 272.377 189.815 269.29 189.815 269.29 192.901 277.006 192.901 277.006 180.556 269.29 180.556"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 220.679 269.29 223.765 272.377 223.765 272.377 229.938 269.29 229.938 269.29 233.025 277.006 233.025 277.006 220.679 269.29 220.679"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 260.802 269.29 263.889 272.377 263.889 272.377 270.062 269.29 270.062 269.29 273.148 277.006 273.148 277.006 260.802 269.29 260.802"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="269.29012"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 300.926 269.29 304.012 272.377 304.012 272.377 310.185 269.29 310.185 269.29 313.272 277.006 313.272 277.006 300.926 269.29 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="269.29 395.062 269.29 398.148 272.377 398.148 272.377 404.321 269.29 404.321 269.29 407.407 277.006 407.407 277.006 395.062 269.29 395.062"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 100.309 306.327 103.395 309.414 103.395 309.414 109.568 306.327 109.568 306.327 112.654 314.043 112.654 314.043 100.309 306.327 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 120.37 306.327 123.457 309.414 123.457 309.414 129.63 306.327 129.63 306.327 132.716 314.043 132.716 314.043 120.37 306.327 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 140.432 306.327 143.519 309.414 143.519 309.414 149.691 306.327 149.691 306.327 152.778 314.043 152.778 314.043 140.432 306.327 140.432"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 180.556 306.327 183.642 309.414 183.642 309.414 189.815 306.327 189.815 306.327 192.901 314.043 192.901 314.043 180.556 306.327 180.556"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 220.679 306.327 223.765 309.414 223.765 309.414 229.938 306.327 229.938 306.327 233.025 314.043 233.025 314.043 220.679 306.327 220.679"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 260.802 306.327 263.889 309.414 263.889 309.414 270.062 306.327 270.062 306.327 273.148 314.043 273.148 314.043 260.802 306.327 260.802"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="306.32716"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 300.926 306.327 304.012 309.414 304.012 309.414 310.185 306.327 310.185 306.327 313.272 314.043 313.272 314.043 300.926 306.327 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="306.327 395.062 306.327 398.148 309.414 398.148 309.414 404.321 306.327 404.321 306.327 407.407 314.043 407.407 314.043 395.062 306.327 395.062"
            fill="#6c63ff"
          />
          <polygon
            points="198.302 373.457 198.302 376.543 201.389 376.543 201.389 382.716 198.302 382.716 198.302 385.802 206.019 385.802 206.019 373.457 198.302 373.457"
            fill="#6c63ff"
          />
          <polygon
            points="198.302 393.519 198.302 396.605 201.389 396.605 201.389 402.778 198.302 402.778 198.302 405.864 206.019 405.864 206.019 393.519 198.302 393.519"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 160.494 158.179 163.58 161.265 163.58 161.265 169.753 158.179 169.753 158.179 172.84 165.895 172.84 165.895 160.494 158.179 160.494"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 239.198 158.179 242.284 161.265 242.284 161.265 248.457 158.179 248.457 158.179 251.543 165.895 251.543 165.895 239.198 158.179 239.198"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 259.259 158.179 262.346 161.265 262.346 161.265 268.519 158.179 268.519 158.179 271.605 165.895 271.605 165.895 259.259 158.179 259.259"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 299.383 158.179 302.469 161.265 302.469 161.265 308.642 158.179 308.642 158.179 311.728 165.895 311.728 165.895 299.383 158.179 299.383"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 356.481 158.179 359.568 161.265 359.568 161.265 365.741 158.179 365.741 158.179 368.827 165.895 368.827 165.895 356.481 158.179 356.481"
            fill="#6c63ff"
          />
          <polygon
            points="158.179 373.457 158.179 376.543 161.265 376.543 161.265 382.716 158.179 382.716 158.179 385.802 165.895 385.802 165.895 373.457 158.179 373.457"
            fill="#6c63ff"
          />
          <rect
            x="238.42593"
            y="66.35802"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="238.42593"
            y="86.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="235.33951"
            y="108.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="235.33951"
            y="206.79012"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="235.33951"
            y="223.76543"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="238.426 121.914 238.426 125 241.512 125 241.512 131.173 238.426 131.173 238.426 134.259 246.142 134.259 246.142 121.914 238.426 121.914"
            fill="#6c63ff"
          />
          <polygon
            points="238.426 160.494 238.426 163.58 241.512 163.58 241.512 169.753 238.426 169.753 238.426 172.84 246.142 172.84 246.142 160.494 238.426 160.494"
            fill="#6c63ff"
          />
          <polygon
            points="238.426 239.198 238.426 242.284 241.512 242.284 241.512 248.457 238.426 248.457 238.426 251.543 246.142 251.543 246.142 239.198 238.426 239.198"
            fill="#6c63ff"
          />
          <rect
            x="326.38889"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="326.38889"
            y="220.67901"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="323.30247"
            y="242.28395"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="323.30247"
            y="341.04938"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="323.30247"
            y="358.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="326.389 256.173 326.389 259.259 329.475 259.259 329.475 265.432 326.389 265.432 326.389 268.519 334.105 268.519 334.105 256.173 326.389 256.173"
            fill="#6c63ff"
          />
          <polygon
            points="326.389 294.753 326.389 297.84 329.475 297.84 329.475 304.012 326.389 304.012 326.389 307.099 334.105 307.099 334.105 294.753 326.389 294.753"
            fill="#6c63ff"
          />
          <polygon
            points="326.389 373.457 326.389 376.543 329.475 376.543 329.475 382.716 326.389 382.716 326.389 385.802 334.105 385.802 334.105 373.457 326.389 373.457"
            fill="#6c63ff"
          />
          <rect
            x="351.08025"
            y="66.35802"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="100.30864"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="406.6358"
            y="81.79012"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="406.6358"
            y="101.85185"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="140.4321"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="406.6358"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="236.11111"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="351.08025"
            y="86.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="351.08025"
            y="279.32099"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="351.08025"
            y="319.44444"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="351.08025"
            y="339.50617"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="347.99383"
            y="108.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="389.66049"
            y="126.54321"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="347.99383"
            y="206.79012"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="347.99383"
            y="223.76543"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="389.66049"
            y="322.53086"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 121.914 351.08 125 354.167 125 354.167 131.173 351.08 131.173 351.08 134.259 358.796 134.259 358.796 121.914 351.08 121.914"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 100.309 377.315 103.395 380.401 103.395 380.401 109.568 377.315 109.568 377.315 112.654 385.031 112.654 385.031 100.309 377.315 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="406.636 120.37 406.636 123.457 409.722 123.457 409.722 129.63 406.636 129.63 406.636 132.716 414.352 132.716 414.352 120.37 406.636 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="406.636 180.556 406.636 183.642 409.722 183.642 409.722 189.815 406.636 189.815 406.636 192.901 414.352 192.901 414.352 180.556 406.636 180.556"
            fill="#6c63ff"
          />
          <polygon
            points="406.636 200.617 406.636 203.704 409.722 203.704 409.722 209.877 406.636 209.877 406.636 212.963 414.352 212.963 414.352 200.617 406.636 200.617"
            fill="#6c63ff"
          />
          <polygon
            points="406.636 138.889 406.636 141.975 409.722 141.975 409.722 148.148 406.636 148.148 406.636 151.235 414.352 151.235 414.352 138.889 406.636 138.889"
            fill="#6c63ff"
          />
          <polygon
            points="391.204 81.79 391.204 84.877 394.29 84.877 394.29 91.049 391.204 91.049 391.204 94.136 398.92 94.136 398.92 81.79 391.204 81.79"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="180.55556"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="217.59259"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="391.204 199.074 391.204 202.16 394.29 202.16 394.29 208.333 391.204 208.333 391.204 211.42 398.92 211.42 398.92 199.074 391.204 199.074"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 120.37 377.315 123.457 380.401 123.457 380.401 129.63 377.315 129.63 377.315 132.716 385.031 132.716 385.031 120.37 377.315 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 140.432 377.315 143.519 380.401 143.519 380.401 149.691 377.315 149.691 377.315 152.778 385.031 152.778 385.031 140.432 377.315 140.432"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 180.556 377.315 183.642 380.401 183.642 380.401 189.815 377.315 189.815 377.315 192.901 385.031 192.901 385.031 180.556 377.315 180.556"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 220.679 377.315 223.765 380.401 223.765 380.401 229.938 377.315 229.938 377.315 233.025 385.031 233.025 385.031 220.679 377.315 220.679"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="277.77778"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 260.802 377.315 263.889 380.401 263.889 380.401 270.062 377.315 270.062 377.315 273.148 385.031 273.148 385.031 260.802 377.315 260.802"
            fill="#6c63ff"
          />
          <polygon
            points="406.636 260.802 406.636 263.889 409.722 263.889 409.722 270.062 406.636 270.062 406.636 273.148 414.352 273.148 414.352 260.802 406.636 260.802"
            fill="#6c63ff"
          />
          <rect
            x="485.33951"
            y="81.79012"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="485.33951"
            y="101.85185"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="485.33951"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="485.34 120.37 485.34 123.457 488.426 123.457 488.426 129.63 485.34 129.63 485.34 132.716 493.056 132.716 493.056 120.37 485.34 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="485.34 180.556 485.34 183.642 488.426 183.642 488.426 189.815 485.34 189.815 485.34 192.901 493.056 192.901 493.056 180.556 485.34 180.556"
            fill="#6c63ff"
          />
          <polygon
            points="485.34 200.617 485.34 203.704 488.426 203.704 488.426 209.877 485.34 209.877 485.34 212.963 493.056 212.963 493.056 200.617 485.34 200.617"
            fill="#6c63ff"
          />
          <polygon
            points="485.34 138.889 485.34 141.975 488.426 141.975 488.426 148.148 485.34 148.148 485.34 151.235 493.056 151.235 493.056 138.889 485.34 138.889"
            fill="#6c63ff"
          />
          <polygon
            points="485.34 260.802 485.34 263.889 488.426 263.889 488.426 270.062 485.34 270.062 485.34 273.148 493.056 273.148 493.056 260.802 485.34 260.802"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="297.83951"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="377.31481"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="391.2037"
            y="336.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 300.926 377.315 304.012 380.401 304.012 380.401 310.185 377.315 310.185 377.315 313.272 385.031 313.272 385.031 300.926 377.315 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="377.315 395.062 377.315 398.148 380.401 398.148 380.401 404.321 377.315 404.321 377.315 407.407 385.031 407.407 385.031 395.062 377.315 395.062"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 100.309 462.191 103.395 465.278 103.395 465.278 109.568 462.191 109.568 462.191 112.654 469.907 112.654 469.907 100.309 462.191 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 120.37 462.191 123.457 465.278 123.457 465.278 129.63 462.191 129.63 462.191 132.716 469.907 132.716 469.907 120.37 462.191 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 140.432 462.191 143.519 465.278 143.519 465.278 149.691 462.191 149.691 462.191 152.778 469.907 152.778 469.907 140.432 462.191 140.432"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 180.556 462.191 183.642 465.278 183.642 465.278 189.815 462.191 189.815 462.191 192.901 469.907 192.901 469.907 180.556 462.191 180.556"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 220.679 462.191 223.765 465.278 223.765 465.278 229.938 462.191 229.938 462.191 233.025 469.907 233.025 469.907 220.679 462.191 220.679"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 260.802 462.191 263.889 465.278 263.889 465.278 270.062 462.191 270.062 462.191 273.148 469.907 273.148 469.907 260.802 462.191 260.802"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="462.19136"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 300.926 462.191 304.012 465.278 304.012 465.278 310.185 462.191 310.185 462.191 313.272 469.907 313.272 469.907 300.926 462.191 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="462.191 395.062 462.191 398.148 465.278 398.148 465.278 404.321 462.191 404.321 462.191 407.407 469.907 407.407 469.907 395.062 462.191 395.062"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 100.309 499.228 103.395 502.315 103.395 502.315 109.568 499.228 109.568 499.228 112.654 506.944 112.654 506.944 100.309 499.228 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 120.37 499.228 123.457 502.315 123.457 502.315 129.63 499.228 129.63 499.228 132.716 506.944 132.716 506.944 120.37 499.228 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 140.432 499.228 143.519 502.315 143.519 502.315 149.691 499.228 149.691 499.228 152.778 506.944 152.778 506.944 140.432 499.228 140.432"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 180.556 499.228 183.642 502.315 183.642 502.315 189.815 499.228 189.815 499.228 192.901 506.944 192.901 506.944 180.556 499.228 180.556"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 220.679 499.228 223.765 502.315 223.765 502.315 229.938 499.228 229.938 499.228 233.025 506.944 233.025 506.944 220.679 499.228 220.679"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 260.802 499.228 263.889 502.315 263.889 502.315 270.062 499.228 270.062 499.228 273.148 506.944 273.148 506.944 260.802 499.228 260.802"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="499.2284"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 300.926 499.228 304.012 502.315 304.012 502.315 310.185 499.228 310.185 499.228 313.272 506.944 313.272 506.944 300.926 499.228 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="499.228 395.062 499.228 398.148 502.315 398.148 502.315 404.321 499.228 404.321 499.228 407.407 506.944 407.407 506.944 395.062 499.228 395.062"
            fill="#6c63ff"
          />
          <polygon
            points="391.204 373.457 391.204 376.543 394.29 376.543 394.29 382.716 391.204 382.716 391.204 385.802 398.92 385.802 398.92 373.457 391.204 373.457"
            fill="#6c63ff"
          />
          <polygon
            points="391.204 393.519 391.204 396.605 394.29 396.605 394.29 402.778 391.204 402.778 391.204 405.864 398.92 405.864 398.92 393.519 391.204 393.519"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 160.494 351.08 163.58 354.167 163.58 354.167 169.753 351.08 169.753 351.08 172.84 358.796 172.84 358.796 160.494 351.08 160.494"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 239.198 351.08 242.284 354.167 242.284 354.167 248.457 351.08 248.457 351.08 251.543 358.796 251.543 358.796 239.198 351.08 239.198"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 259.259 351.08 262.346 354.167 262.346 354.167 268.519 351.08 268.519 351.08 271.605 358.796 271.605 358.796 259.259 351.08 259.259"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 299.383 351.08 302.469 354.167 302.469 354.167 308.642 351.08 308.642 351.08 311.728 358.796 311.728 358.796 299.383 351.08 299.383"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 356.481 351.08 359.568 354.167 359.568 354.167 365.741 351.08 365.741 351.08 368.827 358.796 368.827 358.796 356.481 351.08 356.481"
            fill="#6c63ff"
          />
          <polygon
            points="351.08 373.457 351.08 376.543 354.167 376.543 354.167 382.716 351.08 382.716 351.08 385.802 358.796 385.802 358.796 373.457 351.08 373.457"
            fill="#6c63ff"
          />
          <rect
            x="431.32716"
            y="66.35802"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="431.32716"
            y="86.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="428.24074"
            y="108.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="428.24074"
            y="206.79012"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="428.24074"
            y="223.76543"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="431.327 121.914 431.327 125 434.414 125 434.414 131.173 431.327 131.173 431.327 134.259 439.043 134.259 439.043 121.914 431.327 121.914"
            fill="#6c63ff"
          />
          <polygon
            points="431.327 160.494 431.327 163.58 434.414 163.58 434.414 169.753 431.327 169.753 431.327 172.84 439.043 172.84 439.043 160.494 431.327 160.494"
            fill="#6c63ff"
          />
          <polygon
            points="431.327 239.198 431.327 242.284 434.414 242.284 434.414 248.457 431.327 248.457 431.327 251.543 439.043 251.543 439.043 239.198 431.327 239.198"
            fill="#6c63ff"
          />
          <rect
            x="519.29012"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="519.29012"
            y="220.67901"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="516.2037"
            y="242.28395"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="516.2037"
            y="341.04938"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="516.2037"
            y="358.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="519.29 256.173 519.29 259.259 522.377 259.259 522.377 265.432 519.29 265.432 519.29 268.519 527.006 268.519 527.006 256.173 519.29 256.173"
            fill="#6c63ff"
          />
          <polygon
            points="519.29 294.753 519.29 297.84 522.377 297.84 522.377 304.012 519.29 304.012 519.29 307.099 527.006 307.099 527.006 294.753 519.29 294.753"
            fill="#6c63ff"
          />
          <polygon
            points="519.29 373.457 519.29 376.543 522.377 376.543 522.377 382.716 519.29 382.716 519.29 385.802 527.006 385.802 527.006 373.457 519.29 373.457"
            fill="#6c63ff"
          />
          <rect
            x="543.98148"
            y="66.35802"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="100.30864"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="599.53704"
            y="81.79012"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="599.53704"
            y="101.85185"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="140.4321"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="599.53704"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="236.11111"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="543.98148"
            y="86.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="543.98148"
            y="279.32099"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="543.98148"
            y="319.44444"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="543.98148"
            y="339.50617"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="540.89506"
            y="108.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="582.56173"
            y="126.54321"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="540.89506"
            y="206.79012"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="540.89506"
            y="223.76543"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="582.56173"
            y="322.53086"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 121.914 543.981 125 547.068 125 547.068 131.173 543.981 131.173 543.981 134.259 551.698 134.259 551.698 121.914 543.981 121.914"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 100.309 570.216 103.395 573.302 103.395 573.302 109.568 570.216 109.568 570.216 112.654 577.932 112.654 577.932 100.309 570.216 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="599.537 120.37 599.537 123.457 602.623 123.457 602.623 129.63 599.537 129.63 599.537 132.716 607.253 132.716 607.253 120.37 599.537 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="599.537 180.556 599.537 183.642 602.623 183.642 602.623 189.815 599.537 189.815 599.537 192.901 607.253 192.901 607.253 180.556 599.537 180.556"
            fill="#6c63ff"
          />
          <polygon
            points="599.537 200.617 599.537 203.704 602.623 203.704 602.623 209.877 599.537 209.877 599.537 212.963 607.253 212.963 607.253 200.617 599.537 200.617"
            fill="#6c63ff"
          />
          <polygon
            points="599.537 138.889 599.537 141.975 602.623 141.975 602.623 148.148 599.537 148.148 599.537 151.235 607.253 151.235 607.253 138.889 599.537 138.889"
            fill="#6c63ff"
          />
          <polygon
            points="584.105 81.79 584.105 84.877 587.191 84.877 587.191 91.049 584.105 91.049 584.105 94.136 591.821 94.136 591.821 81.79 584.105 81.79"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="180.55556"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="217.59259"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="584.105 199.074 584.105 202.16 587.191 202.16 587.191 208.333 584.105 208.333 584.105 211.42 591.821 211.42 591.821 199.074 584.105 199.074"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 120.37 570.216 123.457 573.302 123.457 573.302 129.63 570.216 129.63 570.216 132.716 577.932 132.716 577.932 120.37 570.216 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 140.432 570.216 143.519 573.302 143.519 573.302 149.691 570.216 149.691 570.216 152.778 577.932 152.778 577.932 140.432 570.216 140.432"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 180.556 570.216 183.642 573.302 183.642 573.302 189.815 570.216 189.815 570.216 192.901 577.932 192.901 577.932 180.556 570.216 180.556"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 220.679 570.216 223.765 573.302 223.765 573.302 229.938 570.216 229.938 570.216 233.025 577.932 233.025 577.932 220.679 570.216 220.679"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="277.77778"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 260.802 570.216 263.889 573.302 263.889 573.302 270.062 570.216 270.062 570.216 273.148 577.932 273.148 577.932 260.802 570.216 260.802"
            fill="#6c63ff"
          />
          <polygon
            points="599.537 260.802 599.537 263.889 602.623 263.889 602.623 270.062 599.537 270.062 599.537 273.148 607.253 273.148 607.253 260.802 599.537 260.802"
            fill="#6c63ff"
          />
          <rect
            x="678.24074"
            y="81.79012"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="678.24074"
            y="101.85185"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="678.24074"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="678.241 120.37 678.241 123.457 681.327 123.457 681.327 129.63 678.241 129.63 678.241 132.716 685.957 132.716 685.957 120.37 678.241 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="678.241 180.556 678.241 183.642 681.327 183.642 681.327 189.815 678.241 189.815 678.241 192.901 685.957 192.901 685.957 180.556 678.241 180.556"
            fill="#6c63ff"
          />
          <polygon
            points="678.241 200.617 678.241 203.704 681.327 203.704 681.327 209.877 678.241 209.877 678.241 212.963 685.957 212.963 685.957 200.617 678.241 200.617"
            fill="#6c63ff"
          />
          <polygon
            points="678.241 138.889 678.241 141.975 681.327 141.975 681.327 148.148 678.241 148.148 678.241 151.235 685.957 151.235 685.957 138.889 678.241 138.889"
            fill="#6c63ff"
          />
          <polygon
            points="678.241 260.802 678.241 263.889 681.327 263.889 681.327 270.062 678.241 270.062 678.241 273.148 685.957 273.148 685.957 260.802 678.241 260.802"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="297.83951"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="570.21605"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="584.10494"
            y="336.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 300.926 570.216 304.012 573.302 304.012 573.302 310.185 570.216 310.185 570.216 313.272 577.932 313.272 577.932 300.926 570.216 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="570.216 395.062 570.216 398.148 573.302 398.148 573.302 404.321 570.216 404.321 570.216 407.407 577.932 407.407 577.932 395.062 570.216 395.062"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 100.309 655.093 103.395 658.179 103.395 658.179 109.568 655.093 109.568 655.093 112.654 662.809 112.654 662.809 100.309 655.093 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 120.37 655.093 123.457 658.179 123.457 658.179 129.63 655.093 129.63 655.093 132.716 662.809 132.716 662.809 120.37 655.093 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 140.432 655.093 143.519 658.179 143.519 658.179 149.691 655.093 149.691 655.093 152.778 662.809 152.778 662.809 140.432 655.093 140.432"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 180.556 655.093 183.642 658.179 183.642 658.179 189.815 655.093 189.815 655.093 192.901 662.809 192.901 662.809 180.556 655.093 180.556"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 220.679 655.093 223.765 658.179 223.765 658.179 229.938 655.093 229.938 655.093 233.025 662.809 233.025 662.809 220.679 655.093 220.679"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 260.802 655.093 263.889 658.179 263.889 658.179 270.062 655.093 270.062 655.093 273.148 662.809 273.148 662.809 260.802 655.093 260.802"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="655.09259"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 300.926 655.093 304.012 658.179 304.012 658.179 310.185 655.093 310.185 655.093 313.272 662.809 313.272 662.809 300.926 655.093 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="655.093 395.062 655.093 398.148 658.179 398.148 658.179 404.321 655.093 404.321 655.093 407.407 662.809 407.407 662.809 395.062 655.093 395.062"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="63.2716"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="160.49383"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="359.5679"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 100.309 692.13 103.395 695.216 103.395 695.216 109.568 692.13 109.568 692.13 112.654 699.846 112.654 699.846 100.309 692.13 100.309"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 120.37 692.13 123.457 695.216 123.457 695.216 129.63 692.13 129.63 692.13 132.716 699.846 132.716 699.846 120.37 692.13 120.37"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 140.432 692.13 143.519 695.216 143.519 695.216 149.691 692.13 149.691 692.13 152.778 699.846 152.778 699.846 140.432 692.13 140.432"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 180.556 692.13 183.642 695.216 183.642 695.216 189.815 692.13 189.815 692.13 192.901 699.846 192.901 699.846 180.556 692.13 180.556"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="240.74074"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 220.679 692.13 223.765 695.216 223.765 695.216 229.938 692.13 229.938 692.13 233.025 699.846 233.025 699.846 220.679 692.13 220.679"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="280.8642"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 260.802 692.13 263.889 695.216 263.889 695.216 270.062 692.13 270.062 692.13 273.148 699.846 273.148 699.846 260.802 692.13 260.802"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="320.98765"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="692.12963"
            y="373.45679"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 300.926 692.13 304.012 695.216 304.012 695.216 310.185 692.13 310.185 692.13 313.272 699.846 313.272 699.846 300.926 692.13 300.926"
            fill="#6c63ff"
          />
          <polygon
            points="692.13 395.062 692.13 398.148 695.216 398.148 695.216 404.321 692.13 404.321 692.13 407.407 699.846 407.407 699.846 395.062 692.13 395.062"
            fill="#6c63ff"
          />
          <polygon
            points="584.105 373.457 584.105 376.543 587.191 376.543 587.191 382.716 584.105 382.716 584.105 385.802 591.821 385.802 591.821 373.457 584.105 373.457"
            fill="#6c63ff"
          />
          <polygon
            points="584.105 393.519 584.105 396.605 587.191 396.605 587.191 402.778 584.105 402.778 584.105 405.864 591.821 405.864 591.821 393.519 584.105 393.519"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 160.494 543.981 163.58 547.068 163.58 547.068 169.753 543.981 169.753 543.981 172.84 551.698 172.84 551.698 160.494 543.981 160.494"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 239.198 543.981 242.284 547.068 242.284 547.068 248.457 543.981 248.457 543.981 251.543 551.698 251.543 551.698 239.198 543.981 239.198"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 259.259 543.981 262.346 547.068 262.346 547.068 268.519 543.981 268.519 543.981 271.605 551.698 271.605 551.698 259.259 543.981 259.259"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 299.383 543.981 302.469 547.068 302.469 547.068 308.642 543.981 308.642 543.981 311.728 551.698 311.728 551.698 299.383 543.981 299.383"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 356.481 543.981 359.568 547.068 359.568 547.068 365.741 543.981 365.741 543.981 368.827 551.698 368.827 551.698 356.481 543.981 356.481"
            fill="#6c63ff"
          />
          <polygon
            points="543.981 373.457 543.981 376.543 547.068 376.543 547.068 382.716 543.981 382.716 543.981 385.802 551.698 385.802 551.698 373.457 543.981 373.457"
            fill="#6c63ff"
          />
          <rect
            x="624.2284"
            y="66.35802"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="624.2284"
            y="86.41975"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="621.14198"
            y="108.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="621.14198"
            y="206.79012"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="621.14198"
            y="223.76543"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="624.228 121.914 624.228 125 627.315 125 627.315 131.173 624.228 131.173 624.228 134.259 631.944 134.259 631.944 121.914 624.228 121.914"
            fill="#6c63ff"
          />
          <polygon
            points="624.228 160.494 624.228 163.58 627.315 163.58 627.315 169.753 624.228 169.753 624.228 172.84 631.944 172.84 631.944 160.494 624.228 160.494"
            fill="#6c63ff"
          />
          <polygon
            points="624.228 239.198 624.228 242.284 627.315 242.284 627.315 248.457 624.228 248.457 624.228 251.543 631.944 251.543 631.944 239.198 624.228 239.198"
            fill="#6c63ff"
          />
          <rect
            x="712.19136"
            y="200.61728"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="712.19136"
            y="220.67901"
            width="7.71605"
            height="12.34568"
            fill="#6c63ff"
          />
          <rect
            x="709.10494"
            y="242.28395"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="709.10494"
            y="341.04938"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <rect
            x="709.10494"
            y="358.02469"
            width="10.80247"
            height="1.54321"
            fill="#6c63ff"
          />
          <polygon
            points="712.191 256.173 712.191 259.259 715.278 259.259 715.278 265.432 712.191 265.432 712.191 268.519 719.907 268.519 719.907 256.173 712.191 256.173"
            fill="#6c63ff"
          />
          <polygon
            points="712.191 294.753 712.191 297.84 715.278 297.84 715.278 304.012 712.191 304.012 712.191 307.099 719.907 307.099 719.907 294.753 712.191 294.753"
            fill="#6c63ff"
          />
          <polygon
            points="712.191 373.457 712.191 376.543 715.278 376.543 715.278 382.716 712.191 382.716 712.191 385.802 719.907 385.802 719.907 373.457 712.191 373.457"
            fill="#6c63ff"
          />
          <rect
            x="214.43464"
            y="706.53418"
            width="827.6044"
            height="2.55433"
            fill="#2f2e41"
          />
          <path
            d="M464.48048,499.79525v52l7,32s-1,20,0,26-1,16-7,16-9,15-9,15-2,6-7,7-5,0-5,0l-4,10-83,55,16,40s25-9,27-12,19,4,19-3,3-25,9-26,56-24,56-24,26-29,28-40,11-42,11-42l25,42v84l-4,45,44-6s-4-28,0-32,10-52,6-58,2-33,0-37-1-33-6-41-35-90-35-90Z"
            transform="translate(-78.98048 -95.45574)"
            fill="#2f2e41"
          />
          <path
            d="M549.48048,774.79525s-15,25-5,28,29,0,31,0,38,1,38,1,24-5,24-12-15-7-20-8-36-13-36-13Z"
            transform="translate(-78.98048 -95.45574)"
            fill="#2f2e41"
          />
          <path
            d="M361.073,713.92342s-28.90243-3.82749-27.68058,6.54107,11.52662,26.61084,12.32156,28.44607c.53837,1.24292,6.85438,16.97832,10.87521,27.00587a58.47551,58.47551,0,0,0,12.83544,19.65205c3.93123,3.92912,8.216,7.07932,11.02623,5.86207,6.4233-2.78229.46126-16.54652-.60847-21.53206s-2.37991-38.20126-2.37991-38.20126Z"
            transform="translate(-78.98048 -95.45574)"
            fill="#2f2e41"
          />
          <path
            d="M561.98048,334.29525l32-74s-3-7-10-6c0,0-9.5-24.5-27.5-24.5,0,0-27-15-35,0,0,0-17,15-16,29s2,24,2,24-16,2-13,10,4,8,0,11l-4,3s-83,75-37,185l4,17v16s101,1,101,0-2-7,2-11,4-10,4-10-4-20-2-26,3-32-1-38-2-54-2-54l9-17-3-10-3-10Z"
            transform="translate(-78.98048 -95.45574)"
            fill="#8985a8"
          />
          <path
            d="M553.48048,534.79525s-12-33-11-39-2-18-2-18l-4-81s17-72-21-84c0,0-9.61547,4.19556-17.49389,17.697-7.79077,3.47015-17.24439,12.25226-19.50611,34.303-4,39,14,139,23,156s15,29,15,29l9.4065-4.18066c.38843.7705.5935,1.18066.5935,1.18066Z"
            transform="translate(-78.98048 -95.45574)"
            opacity="0.1"
          />
          <circle cx="480" cy="226.83951" r="3" fill="#f2f2f2" />
          <rect x="479" y="226.83951" width="2" height="47" fill="#3f3d56" />
          <path
            d="M522.98048,538.29525l8,17s18-6,18-9-8-16-8-16Z"
            transform="translate(-78.98048 -95.45574)"
            fill="#ffb8b8"
          />
          <path
            d="M511.48048,312.79525s-23,10-27,49,14,139,23,156,15,29,15,29l27-12s-12-33-11-39-2-18-2-18l-4-81S549.48048,324.79525,511.48048,312.79525Z"
            transform="translate(-78.98048 -95.45574)"
            fill="#8985a8"
          />
        </svg>
      </>
    );
  return <DesignImg theme={props.theme} />;
}

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        {skills.data.map((skill, ind) => {
          return (
            <>
              {ind % 2 === 0 ? (
                <div className="skills-main-div">
                  <Fade left duration={2000}>
                    <div className="skills-image-div">
                      <GetSkillSvg fileName={skill.fileName} theme={theme} />
                    </div>
                  </Fade>

                  <div className="skills-text-div">
                    <Fade right duration={1000}>
                      <h1
                        className="skills-heading"
                        style={{ color: theme.text }}
                      >
                        {skill.title}
                      </h1>
                    </Fade>
                    <Fade right duration={1500}>
                      <SoftwareSkill logos={skill.softwareSkills} />
                    </Fade>
                    <Fade right duration={2000}>
                      <div>
                        {skill.skills.map((skillSentence) => {
                          return (
                            <p
                              className="subTitle skills-text"
                              style={{ color: theme.secondaryText }}
                            >
                              {skillSentence}
                            </p>
                          );
                        })}
                      </div>
                    </Fade>
                  </div>
                </div>
              ) : (
                <div className="skills-main-div">
                  <div className="skills-text-div">
                    <Fade left duration={1000}>
                      <h1
                        className="skills-heading"
                        style={{ color: theme.text }}
                      >
                        {skill.title}
                      </h1>
                    </Fade>
                    <Fade left duration={1500}>
                      <SoftwareSkill logos={skill.softwareSkills} />
                    </Fade>
                    <Fade left duration={2000}>
                      <div>
                        {skill.skills.map((skillSentence) => {
                          return (
                            <p
                              className="subTitle skills-text"
                              style={{ color: theme.secondaryText }}
                            >
                              {skillSentence}
                            </p>
                          );
                        })}
                      </div>
                    </Fade>
                  </div>
                  <Fade right duration={2000}>
                    <div className="skills-image-div">
                      <GetSkillSvg fileName={skill.fileName} theme={theme} />
                    </div>
                  </Fade>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  }
}

export default SkillSection;
