import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <Fade>
      <div className="footer-div" style={{ textAlign: "center" }}>
        <img
          src="./logo.png"
          style={{
            width: "50px",
            height: "auto",
            paddingBottom: 0,
            marginBottom: "0",
          }}
        />
        <p
          className="footer-text"
          style={{ color: props.theme.secondaryText, marginTop: 0 }}
        >
          Love to Learn and Create
        </p>
        {/* <ToggleSwitch theme={props.theme} onToggle={props.onToggle}/> */}
      </div>
    </Fade>
  );
}
